import Keycloak from "keycloak-js"

const keycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
  url: process.env.REACT_APP_KEYCLOAK_URL || "",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "",
}

const ACCESS_ROLES = (process.env.REACT_APP_KEYCLOAK_ACCESS_ROLE || "")
  .split(",")
  .map((role) => role.trim())
  .filter((role) => role);

export const _kc = new Keycloak(keycloakConfig);

const hasRequiredRole = () => {
  return ACCESS_ROLES.some((role) => _kc.hasRealmRole(role));
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {
  const token = localStorage.getItem("serviceDashboardAuthToken");
  const refreshToken = localStorage.getItem("serviceDashboardRefreshToken");

  if (token && refreshToken) {
    _kc.token = token;
    _kc.refreshToken = refreshToken;
  }

  _kc
    .init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      checkLoginIframe: false,
    })
    .then(async (authenticated) => {
      if (!authenticated && refreshToken) {
        try {
          await _kc.updateToken(5);
          localStorage.setItem("serviceDashboardAuthToken", _kc.token || "");
          localStorage.setItem(
            "serviceDashboardRefreshToken",
            _kc.refreshToken || "",
          );

          if (!hasRequiredRole()) {
            console.warn("User does not have the required role.");
            doLogout();
            return;
          }

          onAuthenticatedCallback();
        } catch (error) {
          console.warn("Token refresh failed:", error);
          doLogin();
        }
      } else if (authenticated) {
        localStorage.setItem("serviceDashboardAuthToken", _kc.token || "");
        localStorage.setItem(
          "serviceDashboardRefreshToken",
          _kc.refreshToken || "",
        );

          if (!hasRequiredRole()) {
            console.warn("User does not have the required role.");
            doLogout();
            return;
          }

          onAuthenticatedCallback();
      } else {
        console.warn("User is not authenticated");
        doLogin();
      }
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = () => {
  _kc.logout();
  localStorage.removeItem("serviceDashboardAuthToken");
  localStorage.removeItem("serviceDashboardRefreshToken");
};

const getToken = () =>
  _kc.token || localStorage.getItem("serviceDashboardAuthToken");

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: () => void) =>
  _kc
    .updateToken(5)
    .then(() => {
      localStorage.setItem("serviceDashboardAuthToken", _kc.token || "");
      localStorage.setItem(
        "serviceDashboardRefreshToken",
        _kc.refreshToken || "",
      );
      successCallback();
    })
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.name;
const getEmail = () => _kc.tokenParsed?.email;

const hasRealmRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));
const hasClientRole = (roles) =>
  roles.some((role) => _kc.hasResourceRole(role));

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getEmail,
  hasRealmRole,
  hasClientRole,
};

export default AuthService;
